import { inject, Injectable } from "@angular/core";
import { ApiService } from "../../../core/http/api.service";
import { Observable } from "rxjs";
import { UserDTO } from "../../classes/dto/user/user";

@Injectable({
    providedIn: 'root'
})
export class LoginRepository {

    private apiService: ApiService = inject(ApiService);
    private url: string = 'users/auth';

    public login(username: string, password: string): Observable<UserDTO> {
        // TODO: HABRIA QUE CHECKEAR SI ES NECESARIO UN INTERCEPTOR PARA EL APP ID O SI SIEMPRE ES EL MISMO
        const appId: number = 1241;
        return this.apiService.post<{ username: string, password: string, appId: number }, UserDTO>(`${this.url}/login`, {
            username: username,
            password: password,
            appId: appId
        });
    }

    public getSession(): Observable<UserDTO> {
        // TODO: HABRIA QUE CHECKEAR SI ES NECESARIO UN INTERCEPTOR PARA EL APP ID O SI SIEMPRE ES EL MISMO
        const appId: number = 1241;
        return this.apiService.get<UserDTO>(`${this.url}/sesion`, {
            appId: appId
        });
    }
}