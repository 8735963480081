import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserDTO } from '../../shared/classes/dto/user/user';
import { LoginRepository } from '../../shared/repositories/login/login.repository';
import { catchError, map, Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginRepository: LoginRepository = inject(LoginRepository);
  private router: Router = inject(Router);


  private user: UserDTO;

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  public setSesion(userDTO: UserDTO) {
    this.user = userDTO;
    this.accessToken = userDTO.token;
  }

  public getUser(): UserDTO {
    return this.user;
  }

  public isAuthenticated(): Observable<boolean> {
    if (this.user != undefined) {
      return of(true);
    } else {
      return this.loginRepository.getSession().pipe(map((user: UserDTO) => {
        this.setSesion(user);
        return true;
      }), catchError((err) => {
        this.router.navigate(['login']);
        return of(false)
      }))
    }
  }
}
 